import { Routes } from '../routes';
export const typeReceipt = {
  COLLECTED : 0 ,
  PENDIENTS: 1 ,
  REJECTED : 2 ,
}
export const typePayment = {
  kushki: '6',
  niubiz: '2'
}
export const currencyType = {
  PEN: '1',
  USD: '2',
};
export const pathServer = {
  PATH_NET: `${process.env.NODE_ENV}/intranet/`,
  PATH_BEL: `${process.env.PUBLIC_URL}/images/belcorp/`,
  PATH_ICONS: `${process.env.PUBLIC_URL}/images/icons/`,
  PATH_INTRANET: `${process.env.PUBLIC_URL}/images/intranet/`,
  PATH_BOLETA: `${process.env.PUBLIC_URL}/images/boletas/`,
  SLETTER_IMG: `${process.env.PUBLIC_URL}/images/newsletter/`,
  ADVERTISEMENT_IMG: `${process.env.PUBLIC_URL}/images/advertisement/`,
  PATH_BG: `${process.env.PUBLIC_URL}/images/background/`,
  PATH_GIF: `${process.env.PUBLIC_URL}/images/gif/`,
  PATH_SOCIAL: `${process.env.PUBLIC_URL}/images/social/`,
  PATH_PAYMENT: `${process.env.PUBLIC_URL}/images/cardPayment/`,
  PATH_FILE: `${process.env.PUBLIC_URL}/file/`,
};

export const fontSizes = {
  xSmall: '10',
  small: '15',
  medium: '16',
  large: '28',
};

export const Timer = {
  waitingApi: 2000,
  waitingApiLogin: 5000,
};

export const typeDocument = [
  { value: 'L', label: 'DNI' },
  { value: 'E', label: 'Carnet de extranjería' },
  { value: 'R', label: 'RUC' },
  // { value: 'G', label: 'Pasaporte' },
];
Object.freeze(typeDocument);

export const Icons = {
  pciDss: {
    url: `${pathServer.PATH_ICONS}pci_dss.svg`,
  },
  logoCards: {
    url: `${pathServer.PATH_ICONS}logo_cards.svg`,
  },
  logoProtecta: {
    url: `${pathServer.PATH_ICONS}logo_protecta.svg`,
  },
  errorPayment : {
    url: `${pathServer.PATH_ICONS}errorPayment.svg`,
  },
  checkPayment: {
    url: `${pathServer.PATH_ICONS}checkPayment.svg`,
  },
  closeModal: {
    url: `${pathServer.PATH_ICONS}closeModal.svg`,
  },
  kushkiCard: {
    url: `${pathServer.PATH_ICONS}kushkiCard.svg`,
  },
  kushkiTransfer: {
    url: `${pathServer.PATH_ICONS}kushkiTransfer.svg`,
  },
  kushkiCash: {
    url: `${pathServer.PATH_ICONS}kushkiCash.svg`,
  },

  face: {
    url: `${pathServer.PATH_ICONS}carita_1.svg`,
  },
  valid: {
    url: `${pathServer.PATH_ICONS}carita_2.svg`,
  },
  failed: {
    url: `${pathServer.PATH_ICONS}carita_failed.svg`,
  },
  close: {
    url: `${pathServer.PATH_ICONS}icon_close.svg`,
  },
  closePurple: {
    url: `${pathServer.PATH_ICONS}icon_close_purple.svg`,
  },
  closeBlue: {
    url: `${pathServer.PATH_ICONS}icon_close_blue.svg`,
  },
  home: {
    url: `${pathServer.PATH_ICONS}icon_home.svg`,
  },
  user: {
    url: `${pathServer.PATH_ICONS}icon_user.svg`,
  },
  headerHome: {
    url: `${pathServer.PATH_ICONS}logo_oficial.jpeg`,
  },
  default: {
    url: `${pathServer.PATH_ICONS}default.svg`,
  },
  bodyFirst: {
    url: `${pathServer.PATH_ICONS}tramites.svg`,
  },
  bodySecond: {
    url: `${pathServer.PATH_ICONS}consultas.svg`,
  },
  bodyThird: {
    url: `${pathServer.PATH_ICONS}reclamos_blue.svg`,
  },
  bodyFourth: {
    url: `${pathServer.PATH_ICONS}proteccion_que_premia.svg`,
  },
  productoDesgravament: {
    url: `${pathServer.PATH_ICONS}producto_desgravamen.svg`,
  },
  productoSoat: {
    url: `${pathServer.PATH_ICONS}producto_soat.svg`,
  },
  productVDP: {
    url: `${pathServer.PATH_ICONS}productVDP.svg`,
  },
  productVCF: {
    url: `${pathServer.PATH_ICONS}productVCF.svg`,
  },
  serviceUpdate: {
    url: `${pathServer.PATH_ICONS}service-update.svg`,
  },
  serviceSearch: {
    url: `${pathServer.PATH_ICONS}service-search.svg`,
  },
  serviceQuestion: {
    url: `${pathServer.PATH_ICONS}service-question.svg`,
  },
  downloadFormat: {
    url: `${pathServer.PATH_ICONS}download.svg`,
  },
  findTicket: {
    url: `${pathServer.PATH_ICONS}find-ticket.svg`,
  },
  downloadSmall: {
    url: `${pathServer.PATH_ICONS}download-small.svg`,
  },
  chat: {
    url: `${pathServer.PATH_ICONS}chat.svg`,
  },
  successFace: {
    url: `${pathServer.PATH_ICONS}carita_2.svg`,
  },
  successFaceGray: {
    url: `${pathServer.PATH_ICONS}carita.svg`,
  },
  protectionPrize: {
    url: `${pathServer.PATH_ICONS}protection-prize.svg`,
  },
  questionSmall: {
    url: `${pathServer.PATH_ICONS}question.svg`,
  },
  renta: {
    url: `${pathServer.PATH_ICONS}renta.svg`,
  },
  ahorro: {
    url: `${pathServer.PATH_ICONS}ahorro.svg`,
  },
  ahorroTotal: {
    url: `${pathServer.PATH_ICONS}ahorroTotal.svg`,
  },
  riesgo: {
    url: `${pathServer.PATH_ICONS}riesgo.svg`,
  },
  personalAccident: {
    url: `${pathServer.PATH_ICONS}personal-accident.svg`,
  },
  coins: {
    url: `${pathServer.PATH_ICONS}coins.svg`,
  },
  soat: {
    url: `${pathServer.PATH_ICONS}soat.svg`,
  },
  bag: {
    url: `${pathServer.PATH_ICONS}bag.svg`,
  },
  cross: {
    url: `${pathServer.PATH_ICONS}cross.svg`,
  },
  bill: {
    url: `${pathServer.PATH_ICONS}bill.svg`,
  },
  downloadMedium: {
    url: `${pathServer.PATH_ICONS}download-medium.svg`,
  },
  arrowDown: {
    url: `${pathServer.PATH_ICONS}arrow-down.svg`,
  },
  arrowUp: {
    url: `${pathServer.PATH_ICONS}arrow-up.svg`,
  },
  arrowRight: {
    url: `${pathServer.PATH_ICONS}arrow-right.svg`,
  },
  arrowLeft: {
    url: `${pathServer.PATH_ICONS}arrow-left.svg`,
  },
  bar: {
    url: `${pathServer.PATH_ICONS}bar.svg`,
  },
  converturas: {
    url: `${pathServer.PATH_ICONS}converturas.svg`,
  },
  password: {
    url: `${pathServer.PATH_ICONS}eye.svg`,
  },
  cronograma: {
    url: `${pathServer.PATH_ICONS}cronograma.svg`,
  },

  boletas: {
    url: `${pathServer.PATH_ICONS}boletas.svg`,
  },
  downloadBig: {
    url: `${pathServer.PATH_ICONS}download-big.svg`,
  },

  arrowLeftBig: {
    url: `${pathServer.PATH_ICONS}arrow-left-big.svg`,
  },
  arrowRightBig: {
    url: `${pathServer.PATH_ICONS}arrow-right-big.svg`,
  },
  arrowLeftMedium: {
    url: `${pathServer.PATH_ICONS}arrow-left-medium.svg`,
  },
  arrowRightMedium: {
    url: `${pathServer.PATH_ICONS}arrow-right-medium.svg`,
  },
  checkMail: {
    url: `${pathServer.PATH_ICONS}check-mail.svg`,
  },
  facebook: {
    url: `${pathServer.PATH_ICONS}fb.svg`,
  },
  youtube: {
    url: `${pathServer.PATH_ICONS}yt.svg`,
  },
  modePage: {
    url: `${pathServer.PATH_ICONS}change-mode-page.svg`,
  },
  pensionerCertificate: {
    url: `${pathServer.PATH_ICONS}constancia.svg`,
  },
  downloadPoliza: {
    url: `${pathServer.PATH_ICONS}download-poliza.svg`,
  },
  liquidationPayment: {
    url: `${pathServer.PATH_ICONS}liquidation-payment.svg`,
  },
  secureValue: {
    url: `${pathServer.PATH_ICONS}values-secure.svg`,
  },
  printFile: {
    url: `${pathServer.PATH_ICONS}ic-print.svg`,
  },
  downloadFile: {
    url: `${pathServer.PATH_ICONS}ic-download.svg`,
  },
  plusRow: {
    url: `${pathServer.PATH_ICONS}plus-row.svg`,
  },
  zoomIn: {
    url: `${pathServer.PATH_ICONS}zoomIn.svg`,
  },
  zoomOut: {
    url: `${pathServer.PATH_ICONS}zoomOut.svg`,
  },
  constanciaAsegurado: {
    url: `${pathServer.PATH_ICONS}constanciaAsegurado.svg`,
  },
  geolocation: {
    url: `${pathServer.PATH_ICONS}geolocalizacion.svg`,
  },
  phone: {
    url: `${pathServer.PATH_ICONS}phone.svg`,
  },
  map: {
    url: `${pathServer.PATH_ICONS}map.svg`,
  },
  pencil: {
    url: `${pathServer.PATH_ICONS}pencil.svg`,
  },
  certificate: {
    url: `${pathServer.PATH_ICONS}certificate.svg`,
  },
  updateData: {
    url: `${pathServer.PATH_ICONS}updateData.svg`,
  },

  updateCapital: {
    url: `${pathServer.PATH_ICONS}updateCapital.svg`,
  },
  updateSepelio: {
    url: `${pathServer.PATH_ICONS}updateSepelio.svg`,
  },
  mapFrom: {
    url: `${pathServer.PATH_ICONS}map_from.svg`,
  },
  mapTo: {
    url: `${pathServer.PATH_ICONS}map_to.svg`,
  },
  mapSearch: {
    url: `${pathServer.PATH_ICONS}map_search.svg`,
  },
  mapClinica1: {
    url: `${pathServer.PATH_ICONS}map_c1.svg`,
  },
  mapClinica2: {
    url: `${pathServer.PATH_ICONS}map_c2.svg`,
  },
  productoSctr: {
    url: `${pathServer.PATH_ICONS}producto-sctr.svg`,
  },

  productoRenta: {
    url: `${pathServer.PATH_ICONS}producto-renta.svg`,
  },

  productoAccidente: {
    url: `${pathServer.PATH_ICONS}producto-accidente.svg`,
  },
  optionChurch: {
    url: `${pathServer.PATH_ICONS}option-church.svg`,
  },
  optionHeart: {
    url: `${pathServer.PATH_ICONS}option-heart.svg`,
  },
  optionPercentage: {
    url: `${pathServer.PATH_ICONS}option-percentage.svg`,
  },
  optionRuleta: {
    url: `${pathServer.PATH_ICONS}option-ruleta.svg`,
  },

  shareFace: {
    url: `${pathServer.PATH_ICONS}share-face.svg`,
  },
  shareInstagram: {
    url: `${pathServer.PATH_ICONS}share-instagram.svg`,
  },
  shareLinkedin: {
    url: `${pathServer.PATH_ICONS}share-linkedin.svg`,
  },

  shareYoutube: {
    url: `${pathServer.PATH_ICONS}share-youtube.svg`,
  },
  business: {
    url: `${pathServer.PATH_ICONS}business.svg`,
  },
  businessOrange: {
    url: `${pathServer.PATH_ICONS}business-orange.svg`,
  },
  attach: {
    url: `${pathServer.PATH_ICONS}attach.svg`,
  },
  search: {
    url: `${pathServer.PATH_ICONS}search.svg`,
  },
  problemsServer: {
    url: `${pathServer.PATH_ICONS}problems-server.svg`,
  },

  checkPromotion: {
    url: `${pathServer.PATH_ICONS}check-promotion.svg`,
  },
  success: {
    url: `${pathServer.PATH_ICONS}icon-success.svg`,
  },
  broken: {
    url: `${pathServer.PATH_ICONS}enchufe.svg`,
  },
  star: {
    url: `${pathServer.PATH_ICONS}star.svg`,
  },
  starSelected: {
    url: `${pathServer.PATH_ICONS}star-selected.svg`,
  },
  closeModalSurvey: {
    url: `${pathServer.PATH_ICONS}closeModalSurvey.svg`,
  },
  delete: {
    url: `${pathServer.PATH_ICONS}icon-delete.svg`,
  },
  historySolid: {
    url: `${pathServer.PATH_ICONS}history-solid.svg`,
  },
  whatsAppOpen: {
    url: `${pathServer.PATH_ICONS}icon-wsp-open.svg`,
  },
  whatsAppClose: {
    url: `${pathServer.PATH_ICONS}icon-wsp-close.svg`,
  },
  info: {
    url: `${pathServer.PATH_ICONS}icon-info.svg`,
  },
  myCoupons: {
    url: `${pathServer.PATH_ICONS}my-coupons.svg`,
  },
  coupon: {
    url: `${pathServer.PATH_ICONS}coupon.svg`,
  },
  couponWhite: {
    url: `${pathServer.PATH_ICONS}coupon-white.svg`,
  },
  couponSmall: {
    url: `${pathServer.PATH_ICONS}coupon-small.svg`,
  },
  couponGray: {
    url: `${pathServer.PATH_ICONS}coupon-gray.svg`,
  },
  calendar: {
    url: `${pathServer.PATH_ICONS}icon-calendar.svg`,
  },
  time: {
    url: `${pathServer.PATH_ICONS}icon-time.svg`,
  },
  check: {
    url: `${pathServer.PATH_ICONS}icon-check.svg`,
  },
  checkVerified: {
    url: `${pathServer.PATH_ICONS}icon-check-verified.svg`,
  },
  checkCircle: {
    url: `${pathServer.PATH_ICONS}icon-check-circle.svg`,
  },
  filePDF: {
    url: `${pathServer.PATH_ICONS}filePDF.svg`,
  },
  fileXML: {
    url: `${pathServer.PATH_ICONS}fileXML.svg`,
  },
  cardNumber: {
    url: `${pathServer.PATH_ICONS}cardNumber.svg`,
  },
  cardExpiry: {
    url: `${pathServer.PATH_ICONS}cardExpiry.svg`,
  },
  cardCvv: {
    url: `${pathServer.PATH_ICONS}cardCvv.svg`,
  },
  cardDoubt: {
    url: `${pathServer.PATH_ICONS}cardDoubt.svg`,
  },
};

export const Background = {
  main: {
    url: `${pathServer.PATH_BG}bg1.jpg`,
  },
};

export const userDefault = {
  familyName: 'PISCONTE',
  middleName: 'MARQUINA',
  givenName: 'AUGUSTO DOMINGO',
};

export const validateEqualsConsecutive = /^(?!.*([A-Za-zñÑ0-9\s])\1{3})\S{8,64}$/;
export const validateSpace = /^\S*$/;
export const validateLowerCase = /^(?=.*[a-zñ])\S{8,64}$/;
export const validateUpperCase = /^(?=.*[A-ZÑ])\S{8,64}$/;
export const validateNumber = /^(?=.*[0-9])\S{8,64}$/;
export const validateSpecialCharacter = /^(?=.*[.,;:=+-/_/*(){}"'\<>!?¿¡/#@$%&])\S{8,64}$/;

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.(com|pe)$/;
export const nameRegex =
  /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/;

export const services = [
  { description: 'Resumen de coberturas', icon: 'converturas', link: '#' },
  {
    description: 'Preguntas frecuentes',
    icon: 'serviceQuestion',
    link: 'frequentQuestion',
  },
];

export const clinicasDefault = [
  { id: 1, lat: -12.09126, lon: -77.028475, name: 'Clinica Javier Prado', distance: 'a 1km' },
  { id: 2, lat: -12.10865, lon: -77.038502, name: 'Clinica ANGLO AMERICANA', distance: 'a 5km' },
  {
    id: 3,
    lat: -12.093141,
    lon: -77.023957,
    name: 'Clinica Internacional - Medicentro',
    distance: 'a 9km',
  },
];

export const routesSletter = {
  first: '/sletter-first',
  second: '/sletter-second',
  third: '/sletter-third',
  fourth: '/sletter-fourth',
};

export const MAGAZINE_BOLETIN_VIVE_SALUD = '00000003';
export const MAGAZINE_BOLETIN_FINANCIERO = '00000004';
export const MAGAZINE_BOLETIN_EN_VIVO = '00000001';
export const MAGAZINE_BOLETIN_QUEDATE_CASA = '00000002';

export const optionsHeaderNewSletter = [
  // Ocultar seccion En Vivo
  /* {
    id: 0,
    idTipoContenido: MAGAZINE_BOLETIN_EN_VIVO,
    description: 'En Vivo',
    shortDescription: 'EV',
    link: `${Routes.HOME_NEWSLETTER + routesSletter.first}`,
    value: routesSletter.first,
    banner: 'headerBannerFirst.jpg',
    footer: 'footerFirst.png',
  }, */
  {
    id: 1,
    idTipoContenido: MAGAZINE_BOLETIN_QUEDATE_CASA,
    description: '#QuédateEnCasa',
    link: `${Routes.HOME_NEWSLETTER + routesSletter.second}`,
    shortDescription: 'QC',
    value: routesSletter.second,
    banner: 'headerBannerSecond.png',
    footer: 'footerUltSalud.png',
  },
  {
    id: 2,
    idTipoContenido: MAGAZINE_BOLETIN_VIVE_SALUD,
    description: 'Boletín Vive Salud',
    shortDescription: 'BS',
    link: `${Routes.HOME_NEWSLETTER + routesSletter.third}`,
    value: routesSletter.third,
    banner: 'headerBannerThird.png',
    footer: 'footerPrincipal.png',
  },
  {
    id: 3,
    idTipoContenido: MAGAZINE_BOLETIN_FINANCIERO,
    description: 'Boletín Financiero',
    shortDescription: 'BF',
    link: `${Routes.HOME_NEWSLETTER + routesSletter.fourth}`,
    value: routesSletter.fourth,
    banner: 'headerBannerFourth.png',
    footer: 'footerFourth.png',
  },
];

export const STORAGE_TOKEN_COGNITO = 'idTokenCognito';
export const STORAGE_TOKEN_SESSION = 'sessionToken';
export const STORAGE_POLICY = 'policy';
export const STORAGE_TOKEN_INITIAL = 'tokenInitial';
export const STORAGE_TOKEN_IDPROCESS = 'idProcess';
export const NEED_UPDATE_PASSWORD = 'needUpdatePassword';

export const FIELD_NEXTTIME_LOGIN = 'nextTime';
export const STORAGE_TOKEN_APP = 'idTokenApp';

export const STORAGE_IS_BUSINESS = 'isBusiness';
export const SUCCESS = 'SUCCESS';

export const typeMotives = {
  SOLICITUDES: 1,
  TRAMITES: 2,
  CONSULTAS: 3,
  RECLAMOS: 4,
};

export const URL_UPDATE_DATA = '/intranet/update-data';

export const HeaderTableBeneficiary = [
  { elementProp: 'typeDocument', headerColumn: 'Tipo de documento' },
  { elementProp: 'nroDocument', headerColumn: 'Número de documento' },
  { elementProp: 'fullName', headerColumn: 'Nombres y apellidos' },
  { elementProp: 'date', headerColumn: 'Fecha de nacimiento' },
  // { elementProp: 'lastName', headerColumn: 'Apellido Paterno' },
  // { elementProp: 'lastName2', headerColumn: 'Apellido Paterno' },
  // { elementProp: 'name', headerColumn: 'Nombre' },
  { elementProp: 'parent', headerColumn: 'Parentesco' },
  { elementProp: 'percentage', headerColumn: '% Benef', isEdit: true },
];

export const HeaderTableBeneficiaryS = [
  { elementProp: 'typeDocument', headerColumn: 'Tipo de documento' },
  { elementProp: 'nroDocument', headerColumn: 'Número de documento' },
  { elementProp: 'fullName', headerColumn: 'Nombres y apellidos' },
  { elementProp: 'date', headerColumn: 'Fecha de nacimiento' },
  // { elementProp: 'lastName', headerColumn: 'Apellido Paterno' },
  // { elementProp: 'lastName2', headerColumn: 'Apellido Paterno' },
  // { elementProp: 'name', headerColumn: 'Nombre' },
  { elementProp: 'parent', headerColumn: 'Parentesco' },
];

export const OTHER_BANKS = [
  { label: 'Seleccione', value: '' },
  { label: 'Banco de Comercio', value: 'Banco de Comercio' },
  { label: 'Banco de la Nación', value: 'Banco de la Nación' },
  { label: 'Banco Falabella', value: 'Banco Falabella' },

  { label: 'Banco GNB Perú', value: 'Banco GNB Perú' },
  {
    label: 'Banco Interamericano de Finanzas',
    value: 'Banco Interamericano de Finanzas',
  },
  { label: 'Banco Pichincha', value: 'Banco Pichincha' },
  { label: 'Banco Ripley', value: 'Banco Ripley' },
  { label: 'Banco Santander Perú', value: 'Banco Santander Perú' },
  { label: 'Citibank Perú', value: 'Citibank Perú' },
  { label: 'HSBC PERU', value: 'HSBC PERU' },
  { label: 'MiBanco', value: 'MiBanco' },
];
const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

export const PARAMS_PAGINATE = {
  previousLabel: '<',
  nextLabel: '>',
  breakLabel: '...',
  breakClassName: 'break-me',
  containerClassName: 'pagination',
  activeClassName: 'active',
  pageRangeDisplayed: isMobile ? 1 : 6,
  marginPagesDisplayed: isMobile ? 1 : 2,
};

export const COUPON_TYPE = {
  CASH_KUSHKI: '2',
  SMART_LINK_TRANSFER: '4',
  SMART_LINK_CARD: '5',
};

export const COUPON_VIGENCY = {
    PROCESS : "En proceso" ,
    AVAILABLE : "Disponible" ,
};
